import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as partnerStyles from "../styles/partnerSection.module.css"
import {
  faFacebook,
  faFacebookSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"

const PartnerSection = () => {
  return (
    <section className={partnerStyles.section}>
      <h1>Partner</h1>
      <StaticImage
        src="../images/shelter-island-yoga-logo-2021.png"
        alt="Shelter Island yoga, Shelter Island, NY"
        width={300}
      />
      <h4>Shelter Island Yoga & Fitness</h4>
      <h5>13 Winthrop Road, Shelter Island, NY </h5>
      <h4>
        <a href="https://www.facebook.com/shelterislandyoga/">
          <FontAwesomeIcon icon={faFacebookSquare} size="2x" width="1.5rem" />
        </a>
        <a href="https://www.instagram.com/shelterislandyogafitness/">
          <FontAwesomeIcon icon={faInstagramSquare} size="2x" width="1.5rem" />
        </a>
      </h4>
    </section>
  )
}

export default PartnerSection
