import React, { useRef, useEffect } from "react"
import { Loader } from "@googlemaps/js-api-loader"
import iconLogo from "../images/icon-48x48.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationArrow, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"

import * as locationStyles from "../styles/locationSection.module.css"

const position = {
  lat: 33.9812,
  lng: -84.1542,
}

const LocationSection = () => {
  const googleMapRef = useRef()

  const loader = new Loader({
    apiKey: "AIzaSyBqv2OvyV4KeSYNNK2-zl366NIkh4yUtH8",
    version: "weekly",
    libraries: ["places"],
    language: "en",
    region: "US",
  })

  const mapOptions = {
    center: {
      lat: 41.083449,
      lng: -72.346791,
    },
    zoom: 13,
  }

  // Promise
  useEffect(() => {
    loader
      .load()
      .then(() => {
        let map = new window.google.maps.Map(googleMapRef.current, mapOptions)
        let marker = new window.google.maps.Marker({
          position: { lat: 41.083449, lng: -72.346791 },
          map: map,
          title: "Moussa Drame Tennis",
          icon: iconLogo,
        })
        marker.setMap(map)
        //   new google.maps.Map(document.getElementById("map"), mapOptions)
      })
      .catch(e => {
        // do something
      })
  }, [])

  return (
    <section className={locationStyles.section}>
      <h1>Location</h1>
      <div className={locationStyles.container}>
        <h3>
          <FontAwesomeIcon icon={faLocationArrow} size="1x" width="1rem" /> 13
          Winthrop Road, Shelter Island Heights, NY 11965.
        </h3>
        <h3>
          <FontAwesomeIcon icon={faPhoneAlt} size="1x" width="1rem" />
          <a href="+1 (631)749-0160"> +1(631)749-0160</a>
        </h3>
      </div>
      <div ref={googleMapRef} className={locationStyles.box}></div>
    </section>
  )
}

export default LocationSection
