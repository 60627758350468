import React from "react"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import { StaticImage } from "gatsby-plugin-image"
import * as serviceStyles from "../styles/services.module.css"

const Services = () => {
  return (
    <section className={serviceStyles.section} id="services-section">
      <h1> Our Services</h1>
      <div className={serviceStyles.container}>
        <div className={serviceStyles.box}>
          <StaticImage
            src="../images/moussa-drame-tennis-junior-tennis-academy.png"
            alt="Moussa Drame Tennis - Junior Camp"
            // width={290}
            // height={200}
          />
          <h2>Junior Tennis Academy</h2>
          <p>
            Since 2001, we are proud of our tennis camp for boys and
            girls aged 7 to 18. With experienced instructors and 7 courts around
            the island, we coach kids to improve their technique, tactics and
            fitness in a fun and beautiful environment. We take great care to
            group children by age and ability so they have similar skill levels
            and have fun together. Your kids will want to come back every
            summer.
          </p>
        </div>
        <div className={serviceStyles.box}>
          <StaticImage
            src="../images/moussa-drame-tennis-adult-clinic.png"
            alt="Moussa Drame Tennis - Adult CLinic & Lessons"
            // width={290}
            // height={200}
          />
          <h2>Adult Clinic & Lessons</h2>
          <p>
            Adult clinics are everyday afternoons at the Dering Harbor Inn. We
            do drills, Cardio Tennis, fitness drills, doubles drills, and
            doubles match-play. Classes are scheduled in groups of similar skill
            levels. Private and group lessons can be scheduled at either Dering
            Harbor (hard court), or Heights (Har-Tru clay) courts depending on
            your preferred surface.
          </p>
        </div>
        <div className={serviceStyles.box}>
          <StaticImage
            src="../images/moussa-drame-tennis-to-go.png"
            alt="Moussa Drame Tennis - Group Lesson"
            // width={290}
            // height={200}
          />
          <h2>Tennis To Go</h2>
          <p>
            Our pros can travel to your court on Shelter Island for Tennis To
            Go, or anywhere in the Hamptons from Westhampton to Montauk. Some of
            our pros are nationally ranked collegiate players and former touring
            professionals. We have pros who speak English, French, Spanish and
            other languages.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Services
