import React, { useState } from "react"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/Button"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

function ControlledCarousel() {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      prevLabel=""
      nextLabel=""
    >
      <Carousel.Item>
        <StaticImage
          className="d-block w-100"
          src="../images/Dering-harbor-court.png"
          alt="Moussa Drame Tennis players enjoying"
        />

        <Carousel.Caption style={{ background: `rgba(0,0,0,0.5)` }}>
          <h1>Tennis on vacation in Shelter Island and the Hamptons</h1>
          <p>
            Refresh your mind and body by taking your tennis skills to a new
            level while having a lot of fun
          </p>

          <Link variant="link" to="/sign-up-now">
            <Button variant="outline-light">Sign up today</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          className="d-block w-100"
          src="../images/Dering-harbor-ball-basket.png"
          alt="Moussa Drame Tennis team 2018"
        />
        <Carousel.Caption style={{ background: `rgba(0,0,0,0.5)` }}>
          <h1>Dering Harbor Courts</h1>
          <p>
            Adult clinics are everyday afternoons at the Dering Harbor Inn. We
            do drills, Cardio Tennis, fitness drills, doubles drills, and
            doubles match-play.
          </p>
          <Link variant="link" to="/sign-up-now">
            <Button variant="outline-light">Sign up today</Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}
export default ControlledCarousel
