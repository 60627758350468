import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "react-bootstrap/Button"
import * as contactStyles from "../styles/contactSection.module.css"

const ContactSection = () => {
  const [checked, setChecked] = useState(false)
  const handleClick = () => setChecked(!checked)

  return (
    <section className={contactStyles.section} id="contact-us">
      <h1> Contact Us</h1>
      <div className={contactStyles.container}>
        <div className={contactStyles.box}>
          <div>
            <StaticImage
              src="../images/moussa-photo-500h.jpg"
              alt="Moussa Drame Tennis - Director"
            />
          </div>
        </div>
        <div className={contactStyles.box}>
          <p>
            Tell us what you need! We are happy to answer any questions you have
            or provide a quote. We’ll reply to you as soon as we can.
          </p>
          <form
            method="post"
            action="https://us-central1-moussadrametennis-b44b1.cloudfunctions.net/emailMessage"
          >
            <div className={contactStyles.formCol}>
              <div className={contactStyles.formGroup}>
                <label>Name*</label>
                <input
                  type="text"
                  placeholder="Enter your Name"
                  name="name"
                  required
                />
              </div>
              <div>
                <label>Surname</label>
                <input
                  type="text"
                  placeholder="Enter your Surname"
                  name="surname"
                />
              </div>
            </div>
            <div className={contactStyles.formCol}>
              <div className={contactStyles.formGroup}>
                <label>E-mail*</label>
                <input
                  type="email"
                  placeholder="Enter your e-mail"
                  name="email"
                  required
                />
              </div>
              <div>
                <label>Telephone</label>
                <input
                  type="tel"
                  placeholder="Enter your telephone"
                  name="telephone"
                />
              </div>
            </div>
            <div className={contactStyles.formTextarea}>
              <label>Message</label>
              <textarea
                aria-setsize="10"
                rows="5"
                placeholder="Message for us"
                name="message"
              />
            </div>
            <div>
              <p>
                Are you human?
                <span style={{ marginLeft: `5px` }}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleClick}
                  />
                </span>
              </p>
            </div>
            <Button
              type="submit"
              className={contactStyles.button}
              variant="outline-primary"
              disabled={!checked}
              readOnly
            >
              Send message
            </Button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ContactSection
