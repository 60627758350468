import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ControlledCarousel from "../components/controlledCarousel"
import Services from "../components/services"
import ContactSection from "../components/contactSection"
import LocationSection from "../components/locationSection"
import PartnerSection from "../components/partnerSection"

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Home" />
      <ControlledCarousel />
      <Services />
      <ContactSection />
      <LocationSection />
      <PartnerSection />
    </Layout>
  )
}

export default IndexPage
